
.login .control {
    display: inline-block;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
    width: 30%;
    margin-top: 5%;
    text-align: start;

    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
}

.login .version {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    color: #fff;
    padding: 0.5rem;
}

.login .footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%);
    padding: 0.5rem;
    color: #fff;
}

.login .control .logo {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.login .control .title {
    font-weight: 600;
    vertical-align: middle;
}

.login .control .ant-card-head {
    border-bottom: 0;
}

.login .control .ant-card-body {
    padding: 24px;
}
.login .control .content .env .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
}

.ant-select-item {
    padding: 4px 12px;
}

.ant-select-item:hover {
    background: #e6f7ff;
    cursor: pointer;
}

.login .control .content .env .ant-select-selection {
    border-radius: 0;
}

.login .control .content .env .ant-select-selection-search > input {
    border-radius: 0;
    cursor: pointer;
}

.env-select,
.env-select .ant-select-dropdown-menu,
.env-select .ant-select-dropdown-menu-item{
    border-radius: 0!important;
}

.login .control .content .env .ant-select-selection-item {
    padding: 0;
    line-height: 30px;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 0 18px 0 12px;
    cursor: pointer;
}

.login .control .content .env .ant-select-selection-selected-value {
    font-size: 14px;
}

.login .control .content .remember {
    margin-top: 0.5rem;
}

.login .control .content .ant-input-prefix {
    padding-right: 0.25rem;
}

.login .control .content .ant-input-lg {
    font-size: 14px;
}

.login .control .content .ant-form-item {
    margin-bottom: 0;
}

.login .control .content .login {
    margin-top: 0.5rem;
}

.login .ant-card {
    border-radius: 0;
}

.login .ant-card .ant-input {
    border-radius: 0;
}

.login .ant-card .ant-btn {
    border-radius: 0;
    margin-bottom: 1rem;
}

.login .hidden {
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    opacity: 0;
    height: 0;
}

.login .show {
    transition: all 0.3s cubic-bezier(.7,.3,.1,1);
    opacity: 1;
    height: 5rem;
}