

.g6-tooltip {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 12px;
    color: #545454;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 8px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
}

.ho-text-hit {
    color: red;
}

.ho-item-link:hover {
    cursor: pointer;
    color: #1890ff;
}

.ho-map-size {
    /* max-width:480px; */
    /* max-height:480px; */
    width: 800px;
    height: 600px;
}

.ho-keyboard {
    position: relative;
    width: 400px;
    height: 600px;
}

.ho-keyboard-on {
    width: 400px;
    height: 300px;
}

.ho-trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}
  
.ho-trigger:hover {
    color: #1890ff;
}
  
  
.ho-title-light {
    font-size: large;
    background: #fff;
    box-shadow: 1px 1px 0 0 #e8e8e8;
    position: relative;
    height: 64px;
    padding-left: 24px;
    overflow: hidden;
    line-height: 64px;
    margin: auto;
}

.ho-title-dark {
    color: #fff;
    font-size: large;
    background: #fff;
    position: relative;
    height: 64px;
    padding-left: 24px;
    overflow: hidden;
    line-height: 64px;
    margin: auto;
}

.ho-title-log-light {
    position: relative;
    height: 64px;
    padding-left: 8px;
    overflow: hidden;
    line-height: 64px;
    margin: auto;
    /* box-shadow: 1px 1px 0 0 #e8e8e8; */
}

.ho-title-log-dark {
    color: #fff;
    position: relative;
    height: 64px;
    padding-left: 24px;
    overflow: hidden;
    line-height: 64px;
    margin: auto;
}
